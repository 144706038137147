import React, { useState, useEffect } from "react";
import { Navbar, Products, Cart, Checkout } from "./components";

import { commerce } from "./lib/commerce"; // a full backend api with all the routes for fetching/creating the products, deleting, updating, selling, authentication and a lot more things. All of that is stored in this little instance

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom"; // (12) Implementing router (V6)

const App = () => {
  const [products, setProducts] = useState([]); // state for products
  const [cart, setCart] = useState({}); // state for cart
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const fetchProducts = async () => {
    const { data } = await commerce.products.list(); // (5) we have to await to see what is inside of that promise; list is ecommerce GET method
    setProducts(data); // set to state
  };

  const fetchCart = async () => {
    setCart(await commerce.cart.retrieve()); // (8) retrieve is ecommerce GET method; we immidiately set it to state
  };

  const handleAddToCart = async (productId, quantity) => {
    const { cart } = await commerce.cart.add(productId, quantity);
    setCart(cart); // (9) cart after item has been added
  };

  const handleUpdateCartQty = async (productId, quantity) => {
    const { cart } = await commerce.cart.update(productId, { quantity });
    setCart(cart); // (14)
  };

  const handleRemoveFromCart = async (productId) => {
    const { cart } = await commerce.cart.remove(productId);
    setCart(cart); // (15)
  };

  const handleEmptyCart = async () => {
    const { cart } = await commerce.cart.empty();
    setCart(cart); // (16)
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();

    setCart(newCart);
  };

  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

      setOrder(incomingOrder);

      refreshCart();
    } catch (error) {
      setErrorMessage(error.data.error.message); // (35)
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCart();
  }, []);

  // console.log(products);
  // console.log(cart);

  return (
    <Router>
      <div>
        <Navbar totalItems={cart.total_items} />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Products products={products} onAddToCart={handleAddToCart} />
            }
          />
          <Route
            exact
            path="/cart"
            element={
              <Cart
                cart={cart}
                handleUpdateCartQty={handleUpdateCartQty}
                handleRemoveFromCart={handleRemoveFromCart}
                handleEmptyCart={handleEmptyCart}
              />
            }
          />
          <Route exact path="/checkout" element={<Checkout cart={cart} order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage}/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

/**
 * (6) useEffect - use that to fetch the products immediately on the application load
 * our use effect hook is going to have its dependency array set to empty which means
 * that it's only going to run at the start on the render (componentDidMonunt)
 */
