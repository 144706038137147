/**
* Fetching real products from the store
* https://commercejs.com/
*/

import Commerce from '@chec/commerce.js';

/**
* (4) Create new instance of the specific commerce
* Hiding key in .env file
* Restart app once applied
*/ 

export const commerce = new Commerce(process.env.REACT_APP_CHEC_PUBLIC_KEY, true);
